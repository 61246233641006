<script>
    import {mapGetters} from 'vuex'

    export default {
        name: "SequenceItemWrapper",
        props: {
            sequenceId: {
                type: String,
                required: true
            },
            itemId: {
                type: String,
                required: true
            },
            externalCondition: {
                type: Boolean,
                default: undefined
            },
            wrapped: Boolean,
            isParentActive: Boolean
        },
        computed: {
            ...mapGetters(['getCurrentSequence', 'getCurrentSequenceId', 'getCurrentSequenceItemsIds']),
            myConstObj() {
                return this.getCurrentSequence?.items.find(({id}) => id === this.itemId)
            },
            conditionValue() {
                return this.externalCondition !== false
            },
            isActive() {
                return this.getCurrentSequenceItemsIds?.includes(this.itemId)
            },
            isNestedWrapperExist() {
                return this.$children[0]?.$vnode.componentOptions?.tag === 'SequenceItemWrapper'
            }
        },
        mounted() {
            this.$watch(
                t => t.$store.getters.getCurrentSequenceItemsIds,
                ids => {
                    if (this.isEducationModeEnabled
                        && this.getCurrentSequenceId === this.sequenceId
                        && ids.includes(this.itemId)
                        && !this.myConstObj?.without_popup
                        && this.conditionValue)
                        this.$store.dispatch('setWrappedElementBounding', {id: this.itemId, bounding: this.$el.getBoundingClientRect()})
                },
                {immediate: true}
            )
        },
        methods: {
            validate(f, mustReturnTrue) {
                if (!this.isEducationModeEnabled)
                    return f()
                if (this.isActive) {
                    const result = f()
                    if (mustReturnTrue && !result)
                        return
                    if (this.isEducationModeEnabled
                        && this.getCurrentSequenceId === this.sequenceId
                        && this.getCurrentSequenceItemsIds.includes(this.itemId)
                        && this.conditionValue) {
                        this.$store.dispatch('sequenceNextStep', {id: this.itemId})
                    }
                    return result
                } else if (this.isNestedWrapperExist || this.isParentActive)
                    return f()
            },
            validateEvenInactive(f) {
                if (!this.isEducationModeEnabled)
                    return f()
                const result = f()
                if (!result) return result
                if (this.isEducationModeEnabled
                    && this.getCurrentSequenceId === this.sequenceId
                    && this.getCurrentSequenceItemsIds.includes(this.itemId)
                    && this.conditionValue) {
                    this.$store.dispatch('sequenceNextStep', {id: this.itemId})
                }

            }
        },
        render() {
            const slotNode = this.$scopedSlots.default({
                validate: this.validate,
                validateEvenInactive: this.validateEvenInactive,
                isActive: this.isActive
            })

            slotNode.forEach(vnode => {
                if (vnode.componentOptions) {
                    if (!vnode.componentOptions.propsData)
                        vnode.componentOptions.propsData = {}
                    vnode.componentOptions.propsData.wrapped = true
                    vnode.componentOptions.propsData.isParentActive = this.isParentActive || this.isActive
                }
            })

            return slotNode
        }
    }
</script>